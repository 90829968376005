.center-page {
  display: table;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.center-page-inner {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}
