// Styling for columns, e.g. container classes in which elements are placed
// next to each other in a vertical direction.

@import (reference) "../elements/borders.less";

.column {
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: column;
}

.column-with-justify-center {
  .column;
  justify-content: center;
}

.column-with-border {
  .column;
  .box-with-border;
}

.column-with-border-and-padding(@horizontal-padding, @vertical-padding) {
  .column-with-border;
  .large-border-radius;
  padding-left: @horizontal-padding;
  padding-right: @horizontal-padding;
  padding-top: @vertical-padding;
  padding-bottom: @vertical-padding;
}
