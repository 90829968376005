@import (reference) "styles/app/app.less";

.check-in-page {
  .center-page;

  .check-in-title {
    margin: 0 0 12px 0;
  }

  .check-in-time {
    .time-text;
    margin: 0 0 40px 0;
  }

  .ant-select {
    .small-shadow;
    width: 380px;
    border-radius: 4px;

    .ant-select-selector {
      .border;
      height: 54px;
      border-radius: 4px;

      .ant-select-selection-item,
      .ant-select-selection-placeholder {
        .bold;
        padding: 11px 0 0 0;
      }
    }

    .ant-select-arrow {
      right: 24px;

      .anticon svg {
        height: 10px;
        margin: 3px 0 0 0;
      }
    }
  }
}
