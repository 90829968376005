@import (reference) "styles/app/app.less";

@footer-button-width: 312px;
@footer-button-height: 84px;
@footer-button-gap: 12px;

.footer-buttons {
  display: inline-flex;
  gap: 12px;
  position: fixed;
  left: calc((100% - @footer-button-gap - 2 * @footer-button-width) / 2);
  bottom: 64px;

  .footer-button {
    .border;
    .pointer-cursor;
    .hover;
    .small-shadow;
    background-color: @white;
    border-radius: 8px;
    width: @footer-button-width;
    height: @footer-button-height;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;

    .footer-button-contents {
      .footer-button-icon {
        height: 16px;
        margin: -2px 24px 0 0;
      }

      .footer-button-text {
        display: inline-block;
        line-height: 1;
      }
    }
  }
}

@media screen and (max-width: 1400px) {
  .footer-buttons {
    bottom: 12px;
  }
}
