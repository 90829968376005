@import (reference) "styles/app/app.less";

@input-margin: 4px;

/////////////////////////////////////////
// Form input
/////////////////////////////////////////

.code-input {
  .code-input-columns {
    .code-input-column {
      margin: 0 0 @input-margin 0;
    }
  }

  .code-input-number {
    .border;
    .border-radius;
    .big-shadow;
    .pointer-cursor;
    .hover;
    text-align: center;
    margin: @input-margin 0 0 0;
    background-color: @white;
    width: calc((100% - 2 * @input-margin) / 3);
    display: inline-flex;
    justify-content: space-between;
    margin: 0 @input-margin 0 0;

    &:last-child {
      margin-right: 0px;
    }

    .code-input-number-text {
      line-height: 42px;
      width: 100%;
    }
  }

  .zero-row {
    text-align: right;

    @backspace-icon-size: 24px;

    .code-input-number.code-input-backspace {
      display: inline-flex;
      justify-content: center;
      vertical-align: middle;
      height: 42px;

      .num-pad-icon {
        height: 20px;
        margin: 10px 0 0 0;
      }
    }
  }
}

/////////////////////////////////////////
// Password display
/////////////////////////////////////////

@display-margin: 4px;

.password-display {
  display: flex;
  gap: @display-margin;
  margin: 0 0 12px 0;

  .password-display-number {
    .border;
    background-color: @white;
    border-radius: 8px;
    width: calc((100% - 4 * @display-margin) / 5);
    height: 42px;
    text-align: center;

    .password-display-number-text {
      .bold;
      line-height: 42px;
    }
  }
}
