@import (reference) "styles/app/app.less";

@user-login-page-width: 246px;

.user-login-page {
  .center-page;

  .center-page-contents {
    width: @user-login-page-width;
    margin: 0 0 0 calc((100% - @user-login-page-width) / 2);
  }

  .user-login-page-title {
    text-align: center;
    margin: 0 0 16px 0;
  }

  .ant-input-password {
    position: absolute;
    top: -200px;
    width: 0px;
  }

  .find-key-help-text {
    .pointer-cursor;
    text-align: center;
    color: @grey-text;
    position: fixed;
    bottom: 36px;
    width: @user-login-page-width;
    left: calc((100% - @user-login-page-width) / 2);
    font-size: 14px;
  }
}
