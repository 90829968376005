@import (reference) "styles/app/app.less";

/////////////////////////////////
// Check out times
/////////////////////////////////

.check-out-page {
  .center-page;

  .check-in-and-out-times {
    display: inline-flex;
    justify-content: center;
    gap: 32px;
    margin: 0 0 40px 0;

    .time-wrapper {
      p {
        margin: 0 0 12px 0;
      }

      h1 {
        .time-text;
      }
    }

    .time-divider {
      .bold;
      font-size: 18px;
      margin-top: 48px;
    }
  }
}

/////////////////////////////////
// Check out form (inside DOM)
/////////////////////////////////

@input-height: 54px;
@input-width: 320px;

.check-out-page {
  .eitje-form-2-field {
    margin: 0 0 12px 0;

    .eitje-form-2-label {
      margin-bottom: 8px;
    }

    .eitje-dropdown {
      .small-shadow;
      border-radius: 4px;
      width: @input-width;

      .ant-select-selector {
        .border;
        height: @input-height;
        border-radius: 4px;

        .ant-select-selection-item {
          line-height: @input-height;
        }
      }
    }

    .eitje-time-picker {
      .small-shadow;
      .border;
      width: @input-width;
      height: @input-height;
      border-radius: 4px;

      input {
        text-align: center;
        padding: 0 0 0 22px; // make correction for image on the right side, so that text centers in the middle of the div
      }
    }
  }

  .invalid-break-time-error-text {
    color: @red-text;
    margin: 0 0 12px 0;
  }
}

/////////////////////////////////
// Check out form (outside DOM)
/////////////////////////////////

.select-break-time-dropdown {
  width: @input-width;

  .ant-picker-content {
    width: @input-width;

    .ant-picker-time-panel-cell {
      text-align: center;
    }
  }

  .ant-picker-footer {
    .ant-picker-ok button {
      height: 32px;

      span {
        .bold;
        color: @white;
        padding: 0 16px;
      }
    }
  }
}

/////////////////////////////////
// Meal picker
/////////////////////////////////

.meal-picker {
  .meal-picker-title {
    margin: 40px 0 10px 0;
  }

  .eitje-form-2-field-maaltijd {
    margin: unset;

    .eitje-button-select {
      display: inline-flex;
      justify-content: center;
      gap: 10px;

      button {
        .hover-effect;
        height: 44px;
        border-radius: 24px;

        span {
          padding: 0 12px;
        }

        &:active,
        &:focus {
          .hover-effect;
        }

        &.active {
          border: 2px solid @black;
        }

        &:not(.active) {
          opacity: 0.7;
        }
      }
    }
  }
}
