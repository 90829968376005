@import (reference) "styles/app/app.less";

@back-footer-height: 64px;

.back-footer {
  .border;
  .pointer-cursor;
  .hover;
  .small-shadow;
  display: inline-flex;
  justify-content: center;
  border-radius: 8px;
  text-align: center;
  background-color: @white;
  padding: 0 40px;
  height: @back-footer-height;
  position: fixed;
  bottom: 64px;
  left: 50%;
  transform: translate(-50%, 0);

  p {
    .prevent-text-overflow;
    color: @grey-text;
    line-height: @back-footer-height;
  }

  .door-icon {
    height: 24px;
    margin: calc((@back-footer-height - 24px) / 2) 12px 0 0;
  }
}

@media screen and (max-width: 1400px) {
  .back-footer {
    bottom: 12px;
  }
}
