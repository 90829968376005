// Border radius variables.

@small-border-radius: 4px;
@large-border-radius: 8px;

// Default, simple borders.

.border-bottom {
  border-bottom-color: @border-grey;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

.border-right {
  border-right-color: @border-grey;
  border-right-width: 1px;
  border-right-style: solid;
}

.border-left {
  border-left-color: @border-grey;
  border-left-width: 1px;
  border-left-style: solid;
}

.border-top {
  border-top-color: @border-grey;
  border-top-width: 1px;
  border-top-style: solid;
}

.border {
  border-color: @border-grey;
  border-width: 1px;
  border-style: solid;
}

// Unset borders

.unset-border-bottom {
  border-bottom-color: unset;
  border-bottom-width: unset;
  border-bottom-style: unset;
}

.unset-border-right {
  border-right-color: unset;
  border-right-width: unset;
  border-right-style: unset;
}

.unset-border-left {
  border-left-color: unset;
  border-left-width: unset;
  border-left-style: unset;
}

.unset-border-top {
  border-top-color: unset;
  border-top-width: unset;
  border-top-style: unset;
}

.unset-border {
  border-color: unset;
  border-width: unset;
  border-style: unset;
}

// Custom border styles.

.custom-border(@radius, @color) {
  .border;
  border-radius: @radius;
  border-color: @color;
}

.dashed-border {
  .border;
  border-width: 4px;
  border-style: dashed;
}

// Radius styles.

.border-radius {
  border-radius: @small-border-radius;
}

.large-border-radius {
  border-radius: @large-border-radius;
}
