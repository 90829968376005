@import (reference) "../colors/variables.less";

// Below one-line classes are to be mixed into CSS selectors, not to be used
// directly within HTML. In this case we diver form our styling principle of
// not writing one line classes. The reason is that this way, we can easily
// manipulate all text within eitje from one centralized place. Say, if we
// want to change the weight of bold, we merely have to edit the below selector.

.bold {
  font-weight: 600;
}

// Within typography, "book" is the standard name for regular font weights.
.book {
  font-weight: 400;
}

.decreased-line-height {
  line-height: 1;
}

.default-line-height {
  line-height: 1.4;
}

.increased-line-height {
  line-height: 1.5;
}

.red {
  color: @red;
}

.white {
  color: @white;
}

.blue {
  color: @blue;
}

.capitalize {
  text-transform: capitalize;
}

.align-center {
  text-align: center;
}

.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

.base-typography {
  font-family: "system-ui", BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  color: @black-text;
  .default-line-height;
  letter-spacing: -0.2px;
  margin-bottom: 0px;
  font-weight: 400;
  white-space: pre-line;

  &:first-letter {
    text-transform: uppercase;
  }
}

.time-text {
  .base-typography;
  .bold;
  font-size: 64px;
  line-height: 1;
}

h1 {
  .base-typography;
  .bold;
  font-size: 40px;
  line-height: 1;
}

h2 {
  .base-typography;
  font-size: 24px;
  font-weight: 600;
}

h3 {
  .base-typography;
  font-size: 18px;
  font-weight: 600;
}

h4 {
  .base-typography;
  .bold;
  font-size: 14px;
}

p {
  .base-typography;
  font-size: 14px;
}

span {
  .base-typography;
  font-size: 14px;
}

// former .eitje-text-secondary
h5 {
  .base-typography;
  font-size: 12px;
  color: @grey-text;
}

h6 {
  .base-typography;
  font-size: 10px;
}

button {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  span {
    white-space: unset;
  }
}

div:first-letter {
  text-transform: uppercase;
}

a {
  cursor: pointer;
  color: @blue;

  * {
    cursor: pointer;
  }
}

strong,
b {
  font-weight: 600;
}

em,
i {
  font-style: italic;
}

body {
  margin: 0;
  padding: 0;
  background-color: @background-color;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "system-ui", BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

thead,
tbody th,
tbody td {
  background-color: @white;
}


.ant-spin-container {
  height: 100vh;
}
