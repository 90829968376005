@import (reference) "styles/app/app.less";

.header {
  .row;
  justify-content: center;
  z-index: 10;

  .header-inner {
    .row;
    justify-content: space-between;
    max-width: @max-page-width;

    .logo {
      margin: 24px 0 0 0;
      height: 46px;
    }

    .header-right-side {
      z-index: 10;
      padding: 0 12px 0 0;

      h4,
      h3 {
        margin: 36px 0 0 0;
        display: inline-block;
      }

      .environment-name,
      .not-activated {
        margin-right: 16px;
      }

      .language-picker {
        .big-shadow;
        margin: 0 0 0 4px;

        .ant-select-selector {
          .border;
          height: 42px;
          border-radius: 4px;

          .ant-select-selection-item {
            padding-top: 4px;
          }
        }
      }

      .options-button {
        .border;
        .big-shadow;
        .pointer-cursor;
        .border-radius;
        .hover;
        background-color: @white;
        display: inline-block;
        width: 54px;
        text-align: center;
        height: 42px;
        padding: 9px 0 0 0;

        .options-icon {
          width: 22px;
        }
      }
    }
  }
}
