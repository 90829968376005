@import "../app/styles/index.less";

.eitje-form-2-error {
  color: @red;
  margin: 4px 0 0 0;
  font-size: 12px;
}

.eitje-form-2-read-only {
  pointer-events: none;
}
