@import (reference) "styles/app/app.less";

@login-page-width: 580px;

.login-page {
  .center-page;

  .center-page-inner {
    .login-form {
      width: @login-page-width;
      margin: 0 0 0 calc((100% - @login-page-width) / 2);
    }
  }

  ///////////////////////
  // Form
  ///////////////////////

  .keys-row {
    .row;
    justify-content: space-between;
    vertical-align: top;
    align-items: unset;

    .eitje-form-2-field {
      width: calc((100% - 40px) / 2);
      align-self: top;
    }
  }

  .eitje-form-2-label {
    margin: 0 0 16px 0;
    text-align: left;
  }

  .eitje-input {
    height: 46px;
    margin: 0 0 0 0;
    border-radius: 4px;
    padding: 4px 24px;

    &::placeholder {
      color: @grey-text;
    }
  }

  .eitje-form-2-field-env_id {
    margin: 24px 0 0 0;

    .eitje-form-2-label {
      display: none;
    }
  }

  ///////////////////////
  // submit button
  ///////////////////////

  .login-button {
    .small-shadow;
    .hover;
    width: 100%;
    background-color: unset;
    border-radius: 8px;
    margin: 24px 0 24px 0;
    height: 50px;

    span {
      .bold;
    }

    .completed-icon {
      width: 16px;
      margin: 0 12px 0 0;
    }
  }

  ///////////////////////
  // Help text
  ///////////////////////

  .find-keys-help-text {
    .pointer-cursor;
    text-align: center;
    color: @grey-text;
    position: fixed;
    bottom: 36px;
    width: @login-page-width;
    left: calc((100% - @login-page-width) / 2);
    font-size: 14px;
  }

  ///////////////////////
  // Keyboard styling
  ///////////////////////

  .hg-button {
    .hover;
  }
}
