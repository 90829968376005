@import (reference) "styles/app/app.less";

////////////////////////////////
// Page styling
////////////////////////////////

.home-page {
  text-align: center;
  .center-page;
}

////////////////////////////////
// User preview
////////////////////////////////

.home-page .user-preview {
  display: inline-flex;
  gap: 24px;
  margin: 0 0 24px 0;
  position: relative;

  .user-avatar {
    .small-shadow;
    width: 56px;
    height: 56px;
    border-radius: 50%;
    border: solid @white 4px;
  }

  .user-skill {
    @skill-size: 16px;

    .border-radius;
    background-color: @neon-orange;
    height: @skill-size;
    width: @skill-size;
    position: absolute;
    left: 48px;
    top: 20px;
    text-align: center;

    .user-skill-letter {
      .bold;
      color: @white;
      line-height: @skill-size;
    }
  }

  .user-preview-text {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;

    .team-and-skill-set-text {
      color: @grey-text;
    }
  }
}

////////////////////////////////
// Post check out hours overview
////////////////////////////////

.home-page .worked-times-overview {
  display: flex;
  justify-content: center;
  gap: 32px;
  vertical-align: middle;

  .time-divider {
    .bold;
    margin-top: 24px;
  }
}

////////////////////////////////
// Status label
////////////////////////////////

.status-label {
  background-color: @green-background;
  border: 2px solid @green;
  border-radius: 20px;
  display: inline-flex;
  padding: 0 24px;
  height: 42px;
  flex-direction: column;
  justify-content: center;
  margin: 24px 0 56px 0;

  .status-label-contents {
    display: flex;

    .status-label-icon {
      height: 16px;
      margin: 2px 12px 0 0;
    }

    .status-label-text {
      .bold;
      color: @green;
    }
  }
}

////////////////////////////////
// Shift note
////////////////////////////////

@shift-note-width: 372px;

.home-page .shift-note {
  .border-radius;
  width: @shift-note-width;
  margin: 0 0 48px calc((100% - @shift-note-width) / 2);
  background-color: @light-grey;
  padding: 16px 24px;
  text-align: left;

  .shift-note-text {
    color: @grey-text;
  }
}

////////////////////////////////
// Check in and out buttons
////////////////////////////////

@shift-buttons-width: 638px;

.home-page {
  .shift-buttons {
    display: flex;
    width: @shift-buttons-width;
    margin: 0 0 24px calc((100% - @shift-buttons-width) / 2);
    gap: 12px;
    justify-content: center;

    .shift-button {
      .border;
      .big-shadow;
      width: 100%;
      background-color: @white;
      border-radius: 8px;
      display: flex;
      vertical-align: middle;
      height: 104px;

      &:not([disabled]) {
        .hover-effect;
        .pointer-cursor;
      }

      .shift-button-icon-container {
        display: inline-flex;
        flex-direction: column;
        justify-content: center;

        .shift-button-icon {
          height: 36px;
          margin: 0 28px 0 44px;
        }
      }

      .shift-button-text {
        text-align: left;
        display: inline-flex;
        flex-direction: column;
        justify-content: center;

        .shift-button-text-title {
        }
        .shift-button-text-body {
          color: @grey-text;
        }
      }
    }
  }
}
