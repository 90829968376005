.message {
  .border-radius;
  margin: 12px 24px;
  padding: 12px;
  background-color: @light-grey;
  text-align: center;
}

.empty-message {
  text-align: center;
  padding: 24px;
}
